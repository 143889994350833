<template>
    <div class="contentLayout">
        <div class="contentNavigationBar">
            <h2 class="title">사이트정보</h2>
        </div>
        <div class="contentBody">
            <h3 class="contentTitle">사이트정보</h3>
            <p class="requireText">(<em>*</em>) 항목은 필수 항목입니다.</p>
            <table class="infoTable tableFixed">
                <colgroup>
                    <col style="width: 140px" />
                    <col style="width: auto" />
                    <col style="width: 140px" />
                    <col style="width: auto" />
                </colgroup>
                <tbody>
                    <tr>
                        <th class="requiredMark">
                            <label for="siteName">사이트명</label>
                        </th>
                        <td colspan="3">
                            <input
                                id="siteName"
                                name="siteName"
                                type="text"
                                class="inputBox"
                                v-model="siteInfo.siteName"
                                v-validate="'required|max:100'"
                                data-vv-name="siteName"
                                data-vv-as="사이트명"
                            />
                            <p
                                class="validation-text"
                                v-if="errors.has('siteName')"
                            >
                                * {{ errors.first("siteName") }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <label for="siteKeyword">사이트 키워드</label>
                        </th>
                        <td colspan="3">
                            <input
                                id="siteKeyword"
                                name="siteKeyword"
                                type="text"
                                class="inputBox"
                                v-model="siteInfo.siteKeyword"
                                v-validate="'required|max:100'"
                                data-vv-name="siteKeyword"
                                data-vv-as="사이트 키워드"
                            />
                            <p
                                class="validation-text"
                                v-if="errors.has('siteKeyword')"
                            >
                                * {{ errors.first("siteKeyword") }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th class="requiredMark">
                            <label for="siteAddress">사이트 주소</label>
                        </th>
                        <td>
                            <input
                                id="siteAddress"
                                type="text"
                                class="inputBox"
                                v-model="siteInfo.siteAddress"
                                v-validate="'required|max:100'"
                                data-vv-name="siteAddress"
                                data-vv-as="사이트 주소"
                            />
                            <p
                                class="validation-text"
                                v-if="errors.has('siteAddress')"
                            >
                                * {{ errors.first("siteAddress") }}
                            </p>
                        </td>
                        <th class="requiredMark">
                            <label for="adminEmailAddress">관리자 이메일</label>
                        </th>
                        <td>
                            <input
                                id="adminEmailAddress"
                                type="text"
                                class="inputBox"
                                v-model="siteInfo.adminEmailAddress"
                                v-validate="'required|max:50'"
                                data-vv-name="adminEmailAddress"
                                data-vv-as="관리자 이메일"
                            />
                            <p
                                class="validation-text"
                                v-if="errors.has('adminEmailAddress')"
                            >
                                * {{ errors.first("adminEmailAddress") }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th class="requiredMark">
                            <label for="memberBaseRoleNo">회원 기본권한</label>
                        </th>
                        <td>
                            <select
                                id="memberBaseRoleNo"
                                name="memberBaseRoleNo"
                                class="selectBox"
                                v-model="siteInfo.memberBaseRoleNo"
                            >
                                <option
                                    v-for="role in roleList"
                                    :key="role.roleNo"
                                    :value="role.roleNo"
                                    :selected="
                                        siteInfo.memberBaseRoleNo == role.roleNo
                                    "
                                >
                                    {{ role.roleName }} ({{
                                        role.roleDiscription
                                    }})
                                </option>
                            </select>
                        </td>
                        <th class="requiredMark">
                            <label for="adminRoleNo">관리자 권한</label>
                        </th>
                        <td>
                            <select
                                id="adminRoleNo"
                                name="adminRoleNo"
                                class="selectBox"
                                v-model="siteInfo.adminRoleNo"
                                v-validate="'required'"
                                data-vv-name="adminRoleNo"
                                data-vv-as="관리자 권한"
                            >
                                <option
                                    v-for="role in roleList"
                                    :key="role.roleNo"
                                    :value="role.roleNo"
                                    :selected="
                                        siteInfo.adminRoleNo == role.roleNo
                                    "
                                >
                                    {{ role.roleName }} ({{
                                        role.roleDiscription
                                    }})
                                </option>
                            </select>
                            <p
                                class="validation-text"
                                v-if="errors.has('adminEmailAddress')"
                            >
                                * {{ errors.first("adminEmailAddress") }}
                            </p>
                        </td>
                    </tr>
                    <tr>
                        <th class="requiredMark">회원가입 사용</th>
                        <td>
                            <div class="radioBoxWrap">
                                <label>
                                    <input
                                        type="radio"
                                        class="radioBox"
                                        name="testRadio-5"
                                        v-model="siteInfo.memberUseYn"
                                        value="Y"
                                        v-validate="'required'"
                                        data-vv-name="memberUseYn"
                                        data-vv-as="회원가입 사용"
                                    />
                                    <span>사용</span>
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        class="radioBox"
                                        name="testRadio-5"
                                        v-model="siteInfo.memberUseYn"
                                        value="N"
                                    />
                                    <span>미사용</span>
                                </label>
                            </div>
                            <p
                                class="validation-text"
                                v-if="errors.has('memberUseYn')"
                            >
                                * {{ errors.first("memberUseYn") }}
                            </p>
                        </td>
                        <th>사이트 생성일자</th>
                        <td>{{ siteInfo.siteAddDt }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="contentButtonBar">
                <div class="buttonWrap floatRight">
                    <button
                        type="button"
                        class="radiusSmallBtn bgBlue"
                        @click="updateSiteInfo()"
                    >
                        저장
                    </button>
                    <button type="button" class="radiusSmallBtn bgDark">
                        취소
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "SiteInfo",
    data() {
        return {
            siteNo: "",
            siteInfo: {},
            roleList: [],
        };
    },
    created() {
        this.siteNo = this.$route.params.siteNo;
        this.selectSiteInfo();
        this.selectRoleList();
    },
    methods: {
        selectSiteInfo() {
            this.logger.debug(this, "selectSiteInfo()");
            axios
                .post(this.config.contextRoot + "/site/select.do", {
                    siteNo: this.siteNo,
                })
                .then((response) => {
                    this.siteInfo = response.data.siteInfo;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        selectRoleList() {
            this.logger.debug(this, "selectRoleList()");
            axios
                .post(this.config.contextRoot + "/site/selectRoleList.do", {
                    siteNo: this.siteNo,
                })
                .then((response) => {
                    this.roleList = response.data.roleList;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        updateSiteInfo() {
            this.logger.debug(this, "updateSiteInfo()");
            if (!confirm("수정 하시겠습니까?")) {
                return;
            }

            this.$validator
                .validateAll()
                .then((result) => {
                    this.logger.debug(this, "valid:" + result);
                    if (!result) {
                        alert(
                            "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                        );
                        return;
                    }
                    axios
                        .post(
                            this.config.contextRoot + "/site/update.do",
                            this.siteInfo
                        )
                        .then((response) => {
                            if (response.status == 200)
                                alert("수정 되었습니다.");
                        })
                        .catch((error) => {
                            this.exceptionHandler(this, error);
                        });
                })
                .catch(() => {
                    this.logger.debug(this, "-->" + this.error.all());
                    this.$alert(this.errors.all());
                    return false;
                });
        },
    },
    watch: {
        $route(to) {
            this.siteNo = to.params.siteNo;
            this.selectSiteInfo();
            this.selectRoleList();
        },
    },
};
</script>

<style>
</style>