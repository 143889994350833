<template>
<div class="wrapper">
    <Gnb/>
    <Top/>
    <SiteInfo/>
</div>
</template>

<script>
import Gnb from '@/components/gnb/Gnb.vue';
import Top from '@/components/top/Top.vue';
import SiteInfo from '@/components/site/SiteInfo.vue';
export default {
    name : 'SiteInfoLayout',
    components : {
        Gnb,
        Top,
        SiteInfo
    }
}
</script>

<style>

</style>