<template>
    <div class="loginWrapper">
        <div class="loginPage">
            <form @submit.prevent="findPassword()">
                <div class="messageTitleWrap">
                    <h2>비밀번호 찾기</h2>
                    <p>비밀번호 찾기를 위한 정보를 입력해주세요.</p>
                </div>
                <label class="blockLabel">
                    <span>이름</span>
                    <input
                        type="text"
                        name="userName"
                        id="userName"
                        v-model="userName"
                        autocomplete="off"
                        v-validate="'required'"
                        data-vv-as="이름"
                    />
                </label>
                <label class="blockLabel">
                    <span>아이디</span>
                    <input
                        type="text"
                        name="loginId"
                        id="loginId"
                        v-model="loginId"
                        autocomplete="off"
                        v-validate="'required'"
                        data-vv-as="아이디"
                    />
                </label>
                <p class="validation-text" v-if="errors.has('loginId')">
                    * {{ errors.first("loginId") }}
                </p>
                <label class="blockLabel">
                    <span>이메일</span>
                    <input
                        type="text"
                        name="emailAddress"
                        id="emailAddress"
                        v-model="emailAddress"
                        autocomplete="off"
                        v-validate="'required|email'"
                        data-vv-as="이메일"
                    />
                </label>
                <p class="validation-text" v-if="errors.has('emailAddress')">
                    * {{ errors.first("emailAddress") }}
                </p>
                <button class="loginBtn">비밀번호 찾기</button>
            </form>
        </div>
        <footer class="footerLaout">
            <div class="footerSection-1">
                <strong class="footerSectionTitle">Info</strong>
                <address>
                    <p>
                        대전광역시 대덕구 대화로 160, 지원상가 2동
                        304호(대화동, 산업용재유통상가)
                    </p>
                </address>
            </div>
            <div class="footerSection-2">
                <strong class="footerSectionTitle">Customer Service</strong>
                <p>T. 042-624-4055</p>
                <p>(주)오솔정보기술</p>
            </div>
        </footer>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: "FindPassword",
    data() {
        return {
            userName: "",
            loginId: "",
            emailAddress: "",
        };
    },
    methods: {
        findPassword() {
            this.logger.debug(this, this.config.contextRoot + "/findPassword");

            this.$validator.validateAll().then((result) => {
                this.logger.debug(this, "valid:" + result);
                if (!result) {
                    alert(
                        "정보가 제대로 입력되지 않았습니다.\n다시 한번 확인해주세요."
                    );
                    return;
                }

                axios
                    .post("/findPassword", {
                        userName: this.userName,
                        loginId: this.loginId,
                        emailAddress: this.emailAddress,
                    })
                    .then((response) => {
                        if (response.data.result == 1) {
                            alert(
                                "임시 비밀번호를 발송했습니다.\n이메일을 확인해 주세요."
                            );
                        } else {
                            alert(
                                "입력하신 정보와 일치하는 회원이 없습니다.\n다시 한번 확인해 주세요."
                            );
                        }
                    })
                    .catch((error) => {
                        this.exceptionHandler(this, error);
                    });
            });
        },
    },
};
</script>

<style>
</style>