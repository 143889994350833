<template>
<div class="wrapper">
    <Gnb/>
    <Top/>
    <VisitorStats/>
</div>
</template>

<script>
import Gnb from '@/components/gnb/Gnb.vue';
import Top from '@/components/top/Top.vue';
import VisitorStats from '@/components/visitor/VisitorStats.vue';
export default {
    name : 'SiteInfoLayout',
    components : {
        Gnb,
        Top,
        VisitorStats
    }
}
</script>

<style>

</style>