<template>
    <div class="topLayout">
        <div class="infoWrap">
            <span>아이디: {{loginId}}</span>
            <span>이름:{{userName}}({{userIp}})</span>
        </div>
        <button class="btn" type="button" @click="logout()">로그아웃</button>
    </div>
</template>

<script>
export default {
    name : 'Top',
    methods: {
        logout() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$Auth.logout(logoutResult => {
                    if (logoutResult) {
                        this.$router.push(this.config.contextRoot + "/login").catch(()=>{});
                    } else {
                        this.logger.debug(this, "logoutResult:" + logoutResult);
                    }
                });
            }
        }
    },
    computed: {
        loginId() {
            return sessionStorage.getItem("loginId")
        },
        userName() {
            return sessionStorage.getItem("userName")
        },
        userIp() {
            return sessionStorage.getItem("userIp")
        }
    }
}
</script>

<style>

</style>