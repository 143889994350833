<template>
    <div class="siteSelectWrap">
        <select 
            class="selectBox"
            @change="changeSite($event)"
            v-if="siteList.length > 1"
        >
            <option 
                :value="site.siteNo" 
                v-for="site in siteList" 
                :key="site.siteNo"
                :selected="siteNo == site.siteNo"
            >
                {{site.siteName}}
            </option>
        </select>
        <h2 v-if="siteList.length == 1">
            {{siteList[0].siteName}}
        </h2>
    </div>
</template>

<script>
import axios from "axios";
export default {
    name: 'GnbSite',
    data() {
        return {
            siteList : [],
            siteNo : "",
        }
    },
    created() {
        this.selectSiteList();
        this.siteNo = this.$route.params.siteNo;
    },
    methods: {
        selectSiteList() {
            this.logger.debug(this, "selectSiteInfoList()");

            if ( this.global.siteList.length > 0 ) {
                this.siteList = this.global.siteList;
                return;
            }

            axios
                .post(this.config.contextRoot + "/site/selectList.do")
                .then((response) => {
                    this.siteList = response.data.siteList;
                    this.global.siteList  = response.data.siteList;
                })
                .catch((error) => {
                    this.exceptionHandler(this, error);
                });
        },
        changeSite(event) {
            const newPath = this.config.contextRoot + '/site/' + event.target.value;
            this.$router.push(newPath);
        }
    }
}
</script>

<style>

</style>